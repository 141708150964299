import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M20.8911 14.3493L16.9774 16.6091L12.0396 19.4598L7.10176 16.6091L3.1881 14.3493L2.52513 13.9667L1.74255 14.4182V18.0547L3.1881 18.8895L7.10176 21.1493L12.0396 24L16.9774 21.1493L20.8911 18.8895L22.3366 18.0547V14.4182L21.554 13.9667L20.8911 14.3493Z"
      fill="currentColor"
    />
    <path
      d="M12.0396 0L1.74255 5.94535V9.58178L3.1881 10.4158L7.10176 12.6756L12.0396 15.5263L16.9774 12.6756L20.8911 10.4158L22.3366 9.58178V5.94535L12.0396 0Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgComponent;
