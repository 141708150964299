import { BtnIconsProps, useBtnIcons } from '@/components/ui/Btn';
import NextAnchor, { NextAnchorProps, NextAnchorPropsWithRef } from '@/components/ui/NextAnchor';
import { createDefaultsMap, Polymorphic, useDefaultsMap } from '@/components/ui/Polymorphic';
import { SprinklesProps, useSprinklesProps } from '@/styles';
import { mergePropsClassName } from '@liquorice/utils';
import React from 'react';
import * as animateStyles from './Btn.Icon.css';
import { BtnStyleProps, useBtnStyle } from './useBtnStyle';

export type BtnBaseProps = BtnStyleProps & BtnIconsProps & SprinklesProps;

export type BtnProps<
  C extends React.ElementType = 'button',
  P = NoProps
> = Polymorphic.PropsWithRef<C, BtnBaseProps & P>;

export interface BtnComponent<P = NoProps> {
  (props: Polymorphic.OverrideProps<BtnBaseProps & NextAnchorProps, P>): React.ReactNode;
  <C extends React.ElementType = 'button'>(
    props: Polymorphic.Props<C, Polymorphic.OverrideProps<BtnBaseProps, P>>
  ): React.ReactNode;
}

export interface BtnComponentWithRef<P = NoProps> {
  (props: Polymorphic.OverrideProps<BtnBaseProps & NextAnchorPropsWithRef, P>): React.ReactNode;
  <C extends React.ElementType = 'button'>(
    props: Polymorphic.PropsWithRef<C, Polymorphic.OverrideProps<BtnBaseProps, P>>
  ): React.ReactNode;
}

/**
 * A Map of HTML tag > props
 */
const buttonDefaultsMap = createDefaultsMap({
  button: {
    type: 'button',
    role: 'button',
  },
});

const Btn: BtnComponentWithRef = React.forwardRef(function Btn<C extends React.ElementType>(
  { as, ...rest }: BtnProps<C>,
  ref?: Polymorphic.Ref<C>
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let props: React.ComponentPropsWithoutRef<any> = rest;

  // Apply icon props
  props = useBtnIcons(props);

  // Apply Sprinkles props
  props = useSprinklesProps(props);

  // Apply style props
  props = useBtnStyle(props);

  const isLink = as === 'a' || 'href' in props;

  /**
   * Force {@link NextAnchor} as anchor component
   */
  const Component = isLink ? NextAnchor : as || 'button';

  /**
   * Select any default props for the Component
   */
  const defaults = useDefaultsMap(buttonDefaultsMap, Component);

  props = {
    ...defaults,
    ...props,
  };

  return <Component ref={ref} {...mergePropsClassName(props, animateStyles.root)} />;
});

export default Btn;
