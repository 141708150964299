import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="square"
      strokeWidth={2}
      d="M2.667 12h17.666M12 2.667 21.333 12 12 21.333"
    />
  </svg>
);
export default SvgComponent;
