'use client';

import { SprinklesProps, useSprinklesProps } from '@/styles/useSprinkle';
import { SvgIcon, SvgIconProps, SvgIconTypeMap } from '@mui/material';
import * as styles from './Icon.css';

import Error from '@mui/icons-material/Error';
import Facebook from '@mui/icons-material/Facebook';
import Instagram from '@mui/icons-material/Instagram';
import ChevronDown from '@mui/icons-material/KeyboardArrowDown';
import ChevronLeft from '@mui/icons-material/KeyboardArrowLeft';
import ChevronRight from '@mui/icons-material/KeyboardArrowRight';
import ChevronUp from '@mui/icons-material/KeyboardArrowUp';
import External from '@mui/icons-material/Launch';
import Linkedin from '@mui/icons-material/LinkedIn';
import Menu from '@mui/icons-material/Menu';
import Phone from '@mui/icons-material/Phone';
import Twitter from '@mui/icons-material/Twitter';

import Alert from './CustomIcons/Alert';
import ArrowRight from './CustomIcons/ArrowRight';
import ArrowUp from './CustomIcons/ArrowUp';
import ArrowUpRight from './CustomIcons/ArrowUpRight';
import Close from './CustomIcons/Close';
import Logo from './CustomIcons/Logo';
import Minus from './CustomIcons/Minus';
import Play from './CustomIcons/Play';
import Plus from './CustomIcons/Plus';
import Search from './CustomIcons/Search';

export const appIcons = {
  error: Error,
  phone: Phone,
  facebook: Facebook,
  instagram: Instagram,
  twitter: Twitter,
  linkedin: Linkedin,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronDown: ChevronDown,
  chevronUp: ChevronUp,
  external: External,
  menu: Menu,
} as const;

export const customIcons = {
  close: Close,
  alert: Alert,
  arrowUp: ArrowUp,
  arrowRight: ArrowRight,
  arrowUpRight: ArrowUpRight,
  minus: Minus,
  play: Play,
  plus: Plus,
  search: Search,
  logo: Logo,
};

export const icons = { ...appIcons, ...customIcons } as const;

export type AppIcons = typeof appIcons;
export type AppIcon = AppIcons[AppIconName];
export type AppIconName = keyof AppIcons;

export type CustomIcons = typeof customIcons;
export type CustomIcon = CustomIcons[CustomIconName];
export type CustomIconName = keyof CustomIcons;

export type Icons = typeof icons;
export type Icon = Icons[IconNames];
export type IconNames = AppIconName | CustomIconName;
export type { IconNames as IconName };

export type IconProps<
  D extends React.ElementType = SvgIconTypeMap['defaultComponent'],
  P = NoProps
> = SvgIconProps<
  D,
  {
    name: IconNames;
    className?: string;
  } & SprinklesProps &
    P
>;
const Icon = ({ name, ...rest }: IconProps) => {
  const Component = icons[name];
  const props = useSprinklesProps(rest);

  const sx = {
    fontSize: styles.iconVars.fontSize,
    ...props.sx,
  };

  // If name equals the key of any of the customIcons, render the custom icon
  if (name in customIcons)
    return (
      <SvgIcon
        fontSize="inherit"
        // inheritViewBox={true} - Not used as it causes issues with some svgs
        viewBox="0 0 32 32"
        {...props}
        sx={sx}>
        <Component />
      </SvgIcon>
    );

  return <Component fontSize="inherit" {...props} sx={sx} />;
};

export default Icon;
