import Icon, { IconName, IconProps } from '@/components/Icon';
import React from 'react';
import Box from '../Box';
import * as styles from './Btn.Icon.css';

export type BtnIconsProps = {
  endIcon?: IconName | React.ReactElement;
  EndIconProps?: Partial<IconProps>;
  startIcon?: IconName | React.ReactElement;
  StartIconProps?: Partial<IconProps>;
  animateIcon?: boolean;
  animateDirection?: 'up' | 'right' | 'diagonal';
  iconBorder?: boolean;
};

type UseBtnIconsProps = React.PropsWithChildren<
  {
    className?: string;
  } & BtnIconsProps
>;

export const useBtnIcons = <P extends UseBtnIconsProps>(props: P) => {
  const {
    children: label,
    endIcon: maybeEndIcon,
    EndIconProps,
    startIcon: maybeStartIcon,
    StartIconProps,
    animateIcon,
    animateDirection = 'up',
    iconBorder,
    ...rest
  } = props;

  const startIcon = maybeStartIcon ?? StartIconProps?.name;
  const endIcon = maybeEndIcon ?? EndIconProps?.name;

  const startIconIsStringType = typeof startIcon === 'string';
  const endIconIsStringType = typeof endIcon === 'string';

  const startIconEl = startIconIsStringType ? (
    <Icon name={startIcon} {...StartIconProps} />
  ) : (
    startIcon
  );
  const endIconEl = endIconIsStringType ? <Icon name={endIcon} {...EndIconProps} /> : endIcon;

  const animateStartIcon =
    animateIcon && startIconIsStringType ? (
      <Box className={styles.iconWrapper({ iconBorder })}>
        <Box className={styles.hiddenIcon({ direction: animateDirection, iconBorder })}>
          <Icon name={startIcon} {...StartIconProps} />
        </Box>
        <Box className={styles.icon({ direction: animateDirection, iconBorder })}>
          <Icon name={startIcon} {...StartIconProps} />
        </Box>
      </Box>
    ) : (
      startIconEl
    );

  const animateEndIcon =
    animateIcon && endIconIsStringType ? (
      <Box className={styles.iconWrapper({ iconBorder })}>
        <Box className={styles.hiddenIcon({ direction: animateDirection, iconBorder })}>
          <Icon name={endIcon} {...EndIconProps} />
        </Box>
        <Box className={styles.icon({ direction: animateDirection, iconBorder })}>
          <Icon name={endIcon} {...EndIconProps} />
        </Box>
      </Box>
    ) : (
      endIconEl
    );

  const children = (
    <>
      {animateStartIcon}
      {label}
      {animateEndIcon}
    </>
  );

  return {
    ...rest,
    children,
  };
};
